$content-background: $color-10;

$tooltip-background: $color-17;
$tooltip: $color-10;

$link: $color-1;
$link-hover: $color-2;
$link-active: $color-3;
$link-disabled: $color-4;

$editorial-home-container-width: 1125px;
$editorial-home-image-max-width: 548px;
$editorial-home-2-column-width: 548px;
$editorial-home-3-column-width: 355px;
$editorial-container-width: 730px;
$editorial-image-max-width: 355px;
$editorial-image-max-width-mobile: 320px;
$editorial-2-column-width: 355px;
$editorial-3-column-width: 230px;

$scrollup: $color-10;
$scrollup-hover: $color-10;
$scrollup-background: $color-1;
$scrollup-background-hover: $color-1;

$modal-close: $color-14;
